.component {
  position: relative;
  z-index: 0;
  border-bottom: var(--border-bold) var(--border-color-red);

  @media (--viewport-lg) {
    display: none;
  }

  & > .headerContainer {
    position: relative;
    z-index: 1;
    height: var(--header-height-mobile);
  }

  & > .portal {
    height: 10px;
    background-color: orange;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  background-color: var(--bg-color-white);
}

.menuBar {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: var(--size-24);

  & > .logoLayout {
    width: 7.4rem;
    height: 100%;
  }

  & > .languageSwitchLayout {
    /* when we re-enable languageSwitch, remove margin-left on .menuButtonLayout */
    margin-left: auto;
  }

  & > .menuButtonLayout {
    /* remove margin-left when we re-enable languageSwitch */
    margin-left: auto;
  }
}

.overlayWrapper {
  & > .overlay {
    position: fixed;
  }
}

.componentOverlayWithPanel {
  /* floatingUI sets inline style position: fixed on the overlay */
  pointer-events: none; /* prevent overlay from blocking clicks on menubar */

  & > .floatingPanel {
    /* stylelint-disable-next-line kaliber/parent-child-policy */ /* floatingOverlay gets position: fixed from floatingUI, so the parent creates a posititioning context */
    position: absolute;
    inset: calc(var(--header-height-mobile) + var(--border-width-bold)) 0 0;
    height: calc(100% - var(--header-height-mobile));
  }
}

.floatingPanel {
  --transform-duration: var(--duration-300);

  pointer-events: initial; /* compensate overlay's pointer-events: none */
  padding: var(--size-16) 0;
  background-color: var(--bg-color-white);
  transition: transform var(--transform-duration);
  transform-origin: bottom center;
  overflow: hidden auto;
  overscroll-behavior: contain;
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);

  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
    border-radius: var(--border-radius-8);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: var(--border-radius-8);
  }

  @starting-style {
    transform: scaleY(0);
  }
}

.componentMenuList {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  max-height: 100%;
  transition: opacity var(--duration-300) var(--transform-duration);

  @starting-style {
    opacity: 0;
  }
}

.componentLabelWithSubmenu {
  & > .submenuToggle {
    width: 100%;
  }
}

.submenuToggle {
  --border-color: transparent;

  &:hover {
    --border-color: var(--border-color-red);
  }

  display: flex;
  justify-content: stretch;
  align-items: center;

  /* font-size, font-weight and padding should match MenuLink > .componentMobile */
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-bold);

  --icon-rotation: 0;

  &[aria-expanded="true"] {
    --icon-rotation: 180deg;
  }

  & > .toggleIconLayout {
    margin-left: auto;
    width: var(--icon-size-36);
    rotate: var(--icon-rotation);
    transition: rotate var(--duration-300) var(--in-out-quad);
  }
}

.toggleLabel {
  /* settings should match HeaderDesktop > .toggleLabel */
  border-bottom: var(--border-bold) var(--border-color);
  transition: border-color linear var(--duration-100);
}

.componentSubmenu {
  --item-height: 3.125rem;
  --item-count: 10; /* large fallback value; real item-count will be set inline */

  overflow: hidden;
  height: auto;
  transition: height var(--duration-300) var(--in-out-quad);

  &[aria-hidden="true"] {
    height: 0;
  }
}

.portalLink {
  border-top: var(--border-medium) var(--border-color-blue-light);
  padding-top: var(--size-8);
}
