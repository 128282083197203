/* stylelint-disable kaliber/selector-policy */
.componentCookieSettings {
  & :global(.CookieDeclarationTable) {
    font-size: var(--font-size-14);
    border-color: transparent;
    padding: 0;
  }

  & :global(.CookieDeclarationTypeDescription) {
    font-size: var(--font-size-16);
  }

  & :global(.CookieDeclarationTableHeader) {
    white-space: nowrap;
  }

  & :global(.CookieDeclarationType) {
    border: none;
  }
}
