.componentBase {
  --track-height: var(--size-8);
  --bg-color-thumb: var(--bg-color-thumb-primary);
  --bg-color-thumb-hover: var(--bg-color-thumb-primary-hover);
  --bg-color-track: var(--bg-color-track-primary);
  --bg-color-slider: var(--bg-color-slider-primary);
  --bg-color-label: var(--bg-color-label-primary);
  --outline-color-thumb: var(--outline-color-thumb-primary);
  --slider-row-height: 3.4375rem;

  display: grid;
  grid-template-rows: auto var(--slider-row-height);
  gap: var(--size-4);
  border-radius: var(--border-radius-pill);

  & > .slider {
    margin-top: auto;
    width: 100%;
  }
}

.componentSecondary {
  --bg-color-thumb: var(--bg-color-thumb-secondary);
  --bg-color-thumb-hover: var(--bg-color-thumb-secondary-hover);
  --bg-color-track: var(--bg-color-track-secondary);
  --bg-color-slider: var(--bg-color-slider-secondary);
  --bg-color-label: var(--bg-color-label-secondary);
  --outline-color-thumb: var(--outline-color-thumb-secondary);
}

.slider {
  position: relative;
  background-color: var(--bg-color-slider);
  border-radius: inherit;

  & > .trackLayout {
    height: 100%;
    position: absolute;
  }

  & > .valueLabelLayout {
    width: min(100%, 4ch);
    position: absolute;
    top: -1.25rem;
  }
}

.componentTrack {
  background-color: var(--bg-color-track);
  pointer-events: none;
}

.componentRangeInputs {
  display: grid;
  z-index: 0;
  position: relative;

  & > .rangeInputLayout {
    grid-area: 1 / 1;
    width: 100%;
    height: calc(var(--track-height) / 2);
    z-index: 1;
  }
}

.componentRangeInput {
  pointer-events: none;
  outline: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;

  &:focus-visible {
    --outline-color-thumb: var(--color-secondary);
  }

  &::-webkit-slider-thumb {
    box-sizing: content-box;
    background-color: var(--bg-color-thumb);
    border: none;
    outline: var(--border-bold) var(--outline-color-thumb);
    border-radius: var(--border-radius-circle);
    cursor: grab;
    height: var(--size-32);
    width: var(--size-32);
    pointer-events: all;
    position: relative;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    transition: var(--duration-300) linear;
    transition-property: background-color, color;

    &:active {
      cursor: grabbing;
    }

    &:hover {
      background-color: var(--bg-color-thumb-hover);
    }
  }

  &::-moz-range-thumb {
    background-color: var(--bg-color-thumb);
    border: none;
    outline: var(--border-bold) var(--outline-color-thumb);
    border-radius: var(--border-radius-circle);
    cursor: grab;
    height: var(--size-32);
    width: var(--size-32);
    pointer-events: all;
    position: relative;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    transition: var(--duration-300) linear;
    transition-property: background-color, color;

    &:active {
      cursor: grabbing;
    }

    &:hover {
      background-color: var(--bg-color-thumb-hover);
    }
  }
}

.componentValueLabel {
  padding-block: var(--size-4);
  text-align: center;
  background-color: var(--bg-color-label);
  border-radius: var(--border-radius-8);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-16);
}
