.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-10);

  & > .resultsMessage {
    height: 2rlh;
  }
}

.componentSelectedFilters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-10);
}
