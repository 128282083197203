.component {
  border-radius: var(--border-radius-32);
  background-color: var(--bg-color-blue-dark);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > * {
    flex: 1;
  }
}

.componentSlider {
  aspect-ratio: 1;
  cursor: grab;
  overflow: visible !important;

  &:active {
    cursor: grabbing;
  }

  & > .imageContainer {
    position: absolute;
    inset: 0;
  }
}

.imageContainer {
  position: relative;
  z-index: 0;
  border-radius: var(--border-radius-32);
  transition: opacity var(--duration-150);

  & > .imageLayout {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
}

.componentContent {
  padding: var(--size-32);
  display: grid;
  flex-direction: column;
  gap: var(--size-32);

  @media (--viewport-sm) {
    padding: var(--size-48);
  }

  & > .slideIndicatorLayout {
    margin-top: var(--size-32);

    @media (--mouse) {
      max-width: 50%;
    }
  }
}

.componentRequirements {
  display: grid;
  gap: var(--size-32);
  pointer-events: none;

  & > .requirement {
    grid-area: 1 / 1;
  }
}

.componentSlideIndicator {
  --indicator-gap: var(--size-10);

  display: flex;
  gap: var(--size-10);

  & > .indicatorLayout {
    width: 100%;
    height: 5px;
  }
}

.componentIndicator {
  --added-height: var(--size-16);

  background-color: var(--bg-color-white);
  border-radius: var(--border-radius-pill);
  transition: background-color var(--duration-100) linear;
  position: relative;

  &:hover {
    background-color: var(--bg-color-orange);
  }

  &:focus-visible {
    outline-color: var(--color-primary);
  }

  &.isActive {
    background-color: var(--bg-color-orange);
  }

  /* expand clickarea */
  &::after {
    content: "";
    position: absolute;
    inset: calc(-1 * var(--added-height) / 2)
      calc(-1 * var(--indicator-gap) / 2);
    width: calc(var(--indicator-gap) + 100%);
    height: calc(var(--added-height) + 100%);
  }
}

.requirement {
  transition: opacity var(--duration-150);
}
