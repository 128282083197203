.componentBase {
  --border: var(--border-bold) var(--border-color-blue-lighter);
  --bg-color: var(--bg-color-white);
  --padding-block-md: var(--section-block-padding-md);
  --padding-block-lg: var(--section-block-padding-lg);

  padding-block: var(--padding-block-md);
  background-color: var(--bg-color);

  @media (--viewport-lg) {
    padding-block: var(--padding-block-lg);
  }

  &.borderTop {
    border-top: var(--border);
  }

  &.borderBottom {
    border-bottom: var(--border);
  }
}

.componentPrimary {
  --bg-color: var(--bg-color-orange);
}

.componentSecondary {
  --bg-color: var(--bg-color-blue-dark);
}

.componentAccent {
  --bg-color: var(--bg-color-red);
}

.componentNeutral,
.componentNeutralSm {
  --bg-color: var(--bg-color-blue-lighter);
}
.componentNeutralSm {
  --padding-block-lg: var(--padding-block-md);
}
