.componentApplicationBase,
[id^="hs_"] {
  /* ubeeo form uses some absolute positioning, so add positioning context */
  position: relative;
  z-index: 0;

  /*
  styling of embedded ubeeo form should be done using css custom properties set in cssGlobal/atsVars.css

  Things that can not be styled with those vars can be styled as well, but be aware that THIS MIGHT BREAK in the future, for example when ubeeo decides to change class names.
  When adjusting styles in this guerilla manner, make sure that the form remains usable if the styling breaks, and consider if the advantage it brings is worth the risk of it breaking.

  Note that class-selectors like .someClass won't work, because they'll get a hash. Use [class*='someClass'] instead
   */

  /* stylelint-disable kaliber/selector-policy, kaliber/layout-related-properties */
  h1,
  h2,
  h3 {
    line-height: var(--line-height-heading-default);
  }

  input[type="button"] {
    letter-spacing: var(--letter-spacing-button);
  }

  label {
    font-weight: var(--font-weight-bold);

    input + & {
      font-weight: var(--font-weight-regular);
    }
  }

  [class="checkbox-wrap"] + [class="checkbox-wrap"] {
    margin-top: var(--size-8);
  }

  /* checkboxes come with 2 different selectors
  - for privacy policy
  - for werktijden
  */
  input[type="checkbox"] {
    transform-origin: center left;
    scale: 1.75;
    appearance: auto; /* our reset.css sets appearance to auto */

    & + label {
      margin-left: 0.75em;
    }
  }

  [class*="hs_platform_form"] {
    [class*="textfield"]:not([class*="address-lookup-housenumber"]),
    [class*="SelectBox"] {
      padding: var(--size-18);
    }

    [class*="textfield"],
    [class*="SelectBox"] {
      border-width: 2px;
      border-color: var(--border-color-form-field-default);

      &::placeholder {
        color: var(--placholder-color-form-field-default);
      }
    }

    [class*="form-field-text-required"] {
      font-size: var(--font-size-14);
      color: var(--color-blue-600);
    }

    [class*="form-group mandatory"] {
      /* Do not add z-index: 0; here, even if linting rules suggest it. Doing so will conflict with Ubeeo's z-index implementation, causing custom dropdowns to appear behind subsequent form groups. */
      position: relative;

      [class*="form-field-text-required"] {
        position: absolute;
        top: 9px; /* same as label padding */
        right: 0;
      }
    }

    [class*="hs-disclaimer required"] {
      [class*="form-field-text-required"] {
        position: relative;
        top: var(--size-4);
        right: auto;
      }
    }
  }
}
/* stylelint-enable kaliber/selector-policy, kaliber/layout-related-properties */
