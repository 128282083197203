.content {
  padding-bottom: var(--size-18);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-20);

  & > .imageBlock {
    width: 100%;
  }
}

.block {
  padding-inline: var(--size-24);
  display: grid;
  gap: var(--size-4);
  grid-template-columns: auto 1fr;

  @media (--viewport-lg) {
    padding-inline: var(--size-36);
  }

  & > .icon {
    width: var(--icon-size-36);
  }
}

.icon {
  color: var(--icon-color-blue-light);
}

.phoneNumber {
  border-bottom: var(--border-bold) var(--border-color-orange);
}

.imageBlock {
  padding-inline: var(--size-18);

  & > .imageContainer {
    width: 100%;
  }
}

.imageContainer {
  overflow: hidden;
  border-radius: var(--border-radius-24);
}
