.component {
  padding-inline: var(--size-16);
  display: inline-flex;
  align-items: center;
  gap: var(--size-10);
  border-radius: var(--border-radius-pill);
  line-height: var(--line-height-button-default);
  letter-spacing: var(--font-letterspacing-sm);
  background-color: var(--bg-color-blue-light);
  color: var(--text-color-default);
  font-size: var(--font-size-14-18);

  &:has(.iconLayout) {
    padding-left: var(--size-12);
  }

  & > .text {
    height: var(--label-size-40);
  }

  & > .iconLayout {
    flex-shrink: 0;
    width: var(--icon-size-24);
  }
}

.text {
  display: flex;
  align-items: center;
}
