.component {
  --quote-offset: 25%;
  --padding-inline-lg: var(--size-100);

  & > .layout {
    @media (--viewport-lg) {
      margin-right: var(--quote-offset);
      margin-left: calc(-1 * var(--padding-inline-lg));
    }
  }
}

.layout {
  padding: var(--size-28);
  border-radius: var(--border-radius-24);
  border: var(--border-bold) var(--border-color-red);
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  @media (--viewport-lg) {
    padding: var(--size-48) var(--padding-inline-lg);
  }
}

.quote {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-24);
  line-height: var(--line-height-heading-default);
}

.quoteText {
  position: relative;
  z-index: 0;

  &::before {
    position: absolute;
    content: "“";
    transform: translateX(-0.5em);
  }

  &::after {
    content: "”";
  }
}

.author {
  font-weight: var(--font-weight-bold);
  color: var(--text-color-red);
  font-size: var(--font-size-14);
}
