.componentTeaser {
  --icon-container-max-width: 23.438rem;
  --padding: var(--size-24);

  border-radius: var(--border-radius-32);
  background-color: var(--bg-color-orange-light);
  display: flex;
  flex-direction: column;

  @media (--viewport-lg) {
    --padding: var(--size-48) var(--size-100);

    flex-direction: row;
    align-items: center;
  }

  & > .iconContainer {
    flex-shrink: 0;

    @media (--viewport-lg) {
      width: min(100%, var(--icon-container-max-width));
    }
  }

  & > .content {
    @media (--viewport-lg) {
      width: 100%;
    }
  }
}

.iconContainer {
  aspect-ratio: 2 / 1;
  border-radius: var(--border-radius-32);
  background-color: var(--bg-color-orange-lighter);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewport-lg) {
    aspect-ratio: 1;
  }

  & > .iconLayout {
    width: min(40vw, 200px);
  }
}

.content {
  padding: var(--padding);
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  text-align: center;

  @media (--viewport-lg) {
    text-align: left;
    align-items: flex-start;
  }
}

.componentSalaryAnimatedIcon {
  aspect-ratio: 1;
}
