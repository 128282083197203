.componentBase {
  --border-color: var(--border-color-input-primary);
  --border-color-focus: var(--border-color-input-primary-focus);
  --bg-color: var(--bg-color-input-primary);
  --font-size: var(--font-size-input-default);

  display: flex;
  flex-direction: column;
  gap: var(--size-4);
}

.componentSecondary {
  --border-color: var(--border-color-select-secondary);
  --border-color-focus: var(--border-color-select-secondary-focus);
  --bg-color: var(--bg-color-select-secondary);
}

.componentTertiary {
  --border-color: var(--border-color-select-tertiary);
  --border-color-focus: var(--border-color-select-tertiary-focus);
  --bg-color: var(--bg-color-select-tertiary);
}

.selectContainer {
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: "";
    background: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS40Njg4IDE2LjUzMTJMNS40Njg3NSAxMC41MzEyQzUuMTU2MjUgMTAuMjUgNS4xNTYyNSA5Ljc4MTI1IDUuNDY4NzUgOS40Njg3NUM1Ljc1IDkuMTg3NSA2LjIxODc1IDkuMTg3NSA2LjUzMTI1IDkuNDY4NzVMMTIgMTQuOTY4OEwxNy40Njg4IDkuNUMxNy43NSA5LjE4NzUgMTguMjE4OCA5LjE4NzUgMTguNTMxMiA5LjVDMTguODEyNSA5Ljc4MTI1IDE4LjgxMjUgMTAuMjUgMTguNTMxMiAxMC41MzEyTDEyLjUgMTYuNTMxMkMxMi4yMTg4IDE2Ljg0MzggMTEuNzUgMTYuODQzOCAxMS40Njg4IDE2LjUzMTJaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KPC9zdmc+Cg==");
    aspect-ratio: 1;
    width: var(--icon-size-24);
    position: absolute;
    right: var(--arrow-offset-select);
    pointer-events: none;
  }

  & > .select {
    width: 100%;
  }
}

.select {
  border: var(--border-form-field-default) var(--border-color);
  border-radius: var(--border-radius-form-field-default);
  background-color: var(--bg-color);
  font-size: var(--font-size);
  padding: var(--padding-select);
  cursor: pointer;

  &:focus-visible {
    outline-offset: 0.1875rem; /* default offset does not look good with small gap between inputs */
  }

  &[disabled] {
    opacity: 0.7;
    pointer-events: none;
    user-select: none;
  }
}
