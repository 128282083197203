.component {
  display: grid;
  grid-template-columns:
    var(--container-padding-fluid-lg)
    minmax(min-content, 1fr)
    var(--container-padding-fluid-lg);
  background-color: var(--bg-color-blue-lighter);
  border-radius: var(--border-radius-24);

  & > .layout {
    grid-column: 2;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding-block: var(--size-32);

  @media (--viewport-md) {
    padding-block: var(--size-48);
  }

  @media (--viewport-lg) {
    padding-block: var(--size-72);
  }

  &.noPadding {
    padding-block: 0;
  }
}

.componentHeader {
  display: contents;

  @media (--viewport-md) {
    display: flex;
    justify-content: space-between;
    gap: var(--size-24);
  }

  & > .buttonContainer {
    order: 1;
  }
}

.componentList {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
  gap: var(--size-18);

  @media (--viewport-md) {
    grid-template-columns: repeat(4, minmax(150px, 1fr));
  }
}

.componentBenefit {
  padding: var(--size-24) var(--size-16);
  background-color: var(--bg-color-blue-light);
  border-radius: var(--border-radius-24);
  aspect-ratio: 200 / 250;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: var(--size-18);
  hyphens: auto;

  @media (--viewport-lg) {
    padding: var(--size-32) var(--size-24);
  }

  & > .iconLayout {
    width: var(--icon-size-72);
    place-self: center;
  }
}

.componentText {
  text-align: center;
  line-height: var(--line-height-text-small);
  font-size: var(--font-size-16-18);
  overflow-wrap: anywhere;
  text-wrap: balance;
}

.textToSpeechButtonContainer {
  & > .textToSpeechButtonLayout {
    margin-bottom: var(--size-32);
  }
}

.componentContent {
  padding-inline: var(--size-32);

  @media (--viewport-md) {
    padding-inline: var(--size-48);
  }
}
