.overlay_root {
  display: grid;
  place-content: center;
  transition: background-color var(--duration-300) ease-out;

  &.enabled {
    background-color: var(--bg-color-overlay);
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-18);
  position: relative;
  z-index: 0;

  & > .modalContent {
    width: calc(clamp(50vw, var(--container-max-width-xl), 80vw));
  }

  & > .modalCloseButtonLayout {
    align-self: flex-end;

    @media (--viewport-md) {
      align-self: unset;
      position: absolute;
      z-index: 1;
      right: calc(-1 * var(--icon-circle-size-48));
      top: calc(-1 * var(--icon-circle-size-48));
    }
  }
}

.modalContent {
  border-radius: var(--border-radius-24);
  overflow: hidden;
  background-color: var(--border-color-blue-dark);
}
