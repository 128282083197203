.componentBase {
  --border-size: var(--size-4);
  --bg-color: var(--bg-color-orange);
  --outline-color: var(--border-color-blue-dark);

  border: var(--border-size) solid var(--border-color-blue-dark);
  border-radius: calc(var(--border-radius-32) + var(--border-size));
  background-color: var(--bg-color-blue-lighter);

  *:≈ {
    outline-color: var(--focus-outline-color-default);
  }
}

.componentSecondary {
  --bg-color: var(--border-color-blue-dark);
  --outline-color: var(--border-color-blue-dark);
}

.header {
  padding-block: var(--size-60);
  outline: var(--border-size) solid var(--outline-color);
  background-color: var(--bg-color);
  border-radius: calc(var(--border-radius-32) - var(--border-size))
    calc(var(--border-radius-32) - var(--border-size)) var(--border-radius-32)
    var(--border-radius-32);
  display: grid;
  grid-template-columns:
    var(--container-padding-fluid-lg)
    minmax(min-content, 1fr)
    var(--container-padding-fluid-lg);

  & > .layout {
    grid-column: 2;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  row-gap: var(--size-24);
}
