.componentBase {
  --border-color: var(--border-color-input-primary);
  --border-color-focus: var(--border-color-input-primary-focus);
  --bg-color: var(--bg-color-input-primary);
  --padding: var(--padding-input-default);
  --font-size: var(--font-size-input-default);
  --outline-color: var(--focus-outline-color-default);
  --options-max-height: 15.625rem;

  display: flex;
  flex-direction: column;
  gap: var(--size-4);
}

.componentSecondary {
  --border-color: var(--border-color-input-secondary);
  --border-color-focus: var(--border-color-input-secondary-focus);
  --bg-color: var(--bg-color-input-secondary);
}

.dropdown {
  position: relative;

  & > .control {
    width: 100%;
  }

  & > .options {
    top: calc(100% - var(--size-6));
    left: 0;
    position: absolute;
    max-height: var(--options-max-height);
    width: 100%;
    z-index: 1;
  }
}

.control {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'label arrowIcon';
  gap: var(--size-10);
  padding: var(--padding);
  font-size: var(--font-size);
  border: var(--border-form-field-default) var(--border-color);
  border-radius: var(--border-radius-form-field-default);
  background-color: var(--bg-color);
  text-align: left;
  transition: border 0s var(--duration-300);

  &.isOpen {
    border: var(--border-form-field-default) var(--border-color-focus);
    transition: border 0s;
  }

  & > .label {
    grid-area: label;
  }

  & > .iconArrowLayout {
    grid-area: arrowIcon;
    aspect-ratio: 1;
    width: var(--icon-size-24);
  }
}

.options {
  padding-inline: var(--size-24);
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
  overflow: hidden auto;
  height: 0;
  visibility: hidden;
  transition: height var(--duration-300) var(--in-out-quad), visibility 0s var(--duration-300);
  overscroll-behavior: contain;
  scrollbar-color: var(--scrollbar-thumb-color) transparent;
  border: var(--border-form-field-default) var(--border-color-focus);
  border-top: none;
  border-radius: 0 0 var(--border-radius-form-field-default) var(--border-radius-form-field-default);
  background-color: var(--bg-color);
  font-size: var(--font-size);

  & > :first-child {
    margin-top: var(--size-6);
  }

  & > :last-child {
    margin-bottom: var(--size-24);
  }

  &.isOpen {
    height: auto;
    visibility: visible;
    transition: height var(--duration-300) var(--in-out-quad), visibility 0s;
  }

  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: var(--border-radius-8);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: var(--border-radius-8);
  }
}

.option:first-child {
  border-top: var(--border-medium) var(--bg-color-blue-lighter);
  padding-top: var(--size-18);
}

.checkboxLabel {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--size-14);
  cursor: pointer;
  border-radius: var(--border-radius-form-field-default);

  &:focus-visible {
    outline: var(--border-size) solid var(--outline-color);
    background-color: var(--color-background-hover);
  }

  & > .checkbox {
    width: var(--size-32);
  }
}

.checkboxInput {
  display: none;
}

.checkbox {
  --border-color: var(--border-color-checkbox-primary);
  --bg-color: var(--bg-color-checkbox-primary);
  --color-checked: var(--color-filter-checkbox-checked);

  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: transparent;
  border-radius: var(--border-radius-form-field-default);
  border: var(--border-form-field-default) var(--border-color);
  background-color: var(--bg-color);

  &.isChecked {
    --border-color: var(--border-color-checkbox-primary-focus);

    color: var(--color-checked);
  }

  & > .iconLayout {
    width: var(--size-28);
    height: var(--size-28);
  }
}
