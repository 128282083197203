.component {
  padding: var(--size-24);
  background-color: var(--bg-color-blue-lighter);
  border: var(--border-bold) var(--bg-color-blue-lighter);
  border-radius: var(--border-radius-32);
  transition: var(--duration-300) border-color linear;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--size-16);
  grid-template-areas:
    "title    title"
    "metadata icon";

  @media (--viewport-sm) {
    padding: var(--size-32);
  }

  &:hover,
  &:focus-visible {
    border-color: var(--color-blue-500);
  }

  & > .headingLayout {
    grid-area: title;
  }

  & > .metadataLayout {
    grid-area: metadata;
  }

  & > .iconLayout {
    grid-area: icon;
    align-self: end;
  }
}
