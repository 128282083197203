.component {
  --step-icon-size: var(--icon-size-36);

  @media (--viewport-md) {
    --step-icon-size: var(--icon-size-48);
  }

  display: grid;
  grid-template-columns:
    var(--container-padding-fluid-lg)
    minmax(min-content, 1fr)
    var(--container-padding-fluid-lg);
  background-color: var(--bg-color-orange-lighter);
  border-radius: var(--border-radius-24);

  & > .layout {
    grid-column: 2;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding-block: var(--size-32);

  @media (--viewport-md) {
    padding-block: var(--size-48);
  }

  @media (--viewport-lg) {
    flex-direction: row;
    padding-block: var(--size-72);
  }

  & > .headerLayout {
    @media (--viewport-lg) {
      width: 60%;
    }
  }

  & > .stepsLayout {
    @media (--viewport-lg) {
      width: 40%;
    }
  }
}

.componentHeader {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.componentStep {
  padding-bottom: var(--size-16);
  display: flex;
  gap: var(--size-16);
  position: relative;
  z-index: 0;

  & > .iconContainer {
    align-self: flex-start;
    flex-shrink: 0;
    width: var(--step-icon-size);
  }

  & > .contentLayout {
    width: 100%;
  }

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    left: calc(var(--step-icon-size) / 2);
    top: 0;
    width: 4px;
    height: 100%;
    background-image: radial-gradient(
      circle at center,
      var(--bg-color-orange-soft) 2px,
      transparent 2px
    );
    background-size: 100% 10px;
    background-position: center;
    background-repeat: repeat-y;
    transform: translateX(-50%);
    z-index: -1;
  }
}

.iconContainer {
  background-color: var(--bg-color-orange-lighter);
}

.componentContent {
  padding: var(--size-16) var(--size-24);
  background-color: var(--bg-color-orange-light);
  border-radius: var(--border-radius-8);

  & > .buttonWithIconLayout {
    width: 100%;
  }
}

.componentButtonWithIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-16);
  line-height: var(--line-height-button-default);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--font-letterspacing-sm);
  text-align: left;

  & > .chevron {
    flex-shrink: 0;
  }
}

.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: rotate var(--duration-150) ease-out;

  &.isOpen {
    rotate: 180deg;
  }

  & > .iconLayout {
    width: var(--icon-size-24);
  }
}

.componentStepDetails {
  overflow: hidden;
  transition: height var(--duration-300) var(--in-out-quad);
  height: 0;
  font-size: var(--font-size-14);
  line-height: var(--line-height-text-small);

  &.isOpen {
    height: auto;
  }
}
