.component {
  /*
  styling of embedded ubeeo form should be done using css custom properties set in cssGlobal/atsVars.css

  Things that can not be styled with those vars can be styled as well, but be aware that THIS MIGHT BREAK in the future, for example when ubeeo decides to change class names.
  When adjusting styles in this guerilla manner, make sure that the form remains usable if the styling breaks, and consider if the advantage it brings is worth the risk of it breaking.
   */

  h1,
  h2,
  h3 {
    line-height: var(--line-height-heading-default);
  }

  input[type="button"] {
    letter-spacing: var(--letter-spacing-button);
  }

  label {
    font-weight: var(--font-weight-bold);
  }

  input + label {
    font-weight: var(--font-weight-regular);
  }

  input[type="checkbox"] {
    display: inline-block;
    transform-origin: center left;
    scale: 1.25;
  }
}
