.component {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  /* stylelint-disable-next-line kaliber/layout-related-properties */
  min-height: 100vh;

  & > .skipLinkLayout {
    position: fixed;
    top: var(--size-12);
    left: var(--size-12);
    width: fit-content;
    z-index: 2;
  }

  & > .main {
    flex-grow: 1;
  }

  & > .cookieBarLayout {
    bottom: 0;
  }
}

.main {
  display: flex;
  flex-direction: column;
}

.componentSkipLink {
  background-color: var(--bg-color-red);
  color: var(--text-color-white);
  border-radius: var(--border-radius-pill);
  line-height: var(--line-height-button-default);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-letterspacing-sm);
  font-size: var(--font-size-16);
  outline: none;
  padding: var(--size-12) var(--size-24);
  display: flex;
  align-items: center;
  transform: translateY(calc(-100% + var(--size-12) * -1));
  opacity: 0;
  pointer-events: none;

  &:focus {
    opacity: 1;
    transform: translateY(0%);
    pointer-events: auto;
  }
}

.header {
  z-index: 1;
}
