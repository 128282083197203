.component {
  --gap: var(--section-block-padding-md);

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  @media (--viewport-lg) {
    --gap: var(--section-block-padding-lg);
  }
}

.imageContainer {
  border-radius: var(--border-radius-24);
  border: var(--border-bold) var(--border-color-blue-dark);
  overflow: hidden;

  & > .imageLayout {
    width: 100%;
  }
}
