.component {
  display: none;
  background-color: var(--bg-color-white);
  border-bottom: var(--border-bold) var(--border-color-red);

  @media (--viewport-lg) {
    display: flex;
    align-items: center;
  }
}

.header {
  display: flex;
  gap: var(--size-16);
  align-items: center;

  & > .logoLayout {
    flex: 0 0 auto;
    width: 8rem;
  }

  & > .navBarLayout {
    margin-left: auto;
  }
}

.componentLogo {
  & > .logoWithTextLayout {
    width: 100%;
  }
}

.menuList {
  display: flex;
}

.submenuToggle {
  --border-color: transparent;

  /* settings should match MenuLink > .componentSubmenuDesktop */
  font-size: var(--size-16);
  font-weight: var(--font-weight-regular);
  padding: var(--size-8) var(--size-16);
  cursor: default;

  &:hover,
  &:focus-visible {
    --border-color: var(--border-color-red);
  }
}

.toggleLabel {
  border-bottom: var(--border-bold) var(--border-color);
  transition: border-color linear var(--duration-100);
  display: block;
}

.floatingPanel {
  border-radius: 0 0 var(--border-radius-24) var(--border-radius-24);
  padding: var(--size-32);
  background-color: var(--bg-color-white);
  transition: opacity var(--duration-300);
  opacity: 0;
  pointer-events: none;

  &.isOpen {
    opacity: 1;
    pointer-events: auto;
  }

  @starting-style {
    opacity: 0;
  }
}

.componentSubmenu {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
}
