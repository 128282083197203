.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-48);
}

.headingContainer {
  text-align: center;

  @media (--viewport-md) {
    text-align: left;
  }
}
