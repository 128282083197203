.component {
  margin: 6vmin auto 0;
}

.layout {
  display: flex;
  align-items: flex-start;
  gap: var(--size-18);

  & > .content {
    height: fit-content;
    margin-top: calc(-1 * var(--size-16));
    max-width: 50ch;
  }
}

.content {
  & > .headingLayout {
    margin-bottom: var(--size-16);
  }

  & > .buttonLayout {
    margin-top: var(--size-16);
  }
}
