.component {
  --padding-start: var(--hero-block-padding-default);
  --aspect-ratio: 360 / 220;

  @media (--viewport-md) {
    --padding-start: var(--hero-block-padding-md);
    --aspect-ratio: unset;
  }

  @media (--viewport-lg) {
    --padding-start: var(--hero-block-padding-lg);
  }

  padding-top: var(--padding-start);
  hyphens: auto;
  word-break: break-word;
  text-wrap: pretty;
}

.layout {
  padding: var(--size-24);
  display: flex;
  aspect-ratio: 360 / 220;
  border-radius: var(--border-radius-32);
  border: var(--border-bold) var(--border-color-blue-dark);
  hyphens: auto;

  @media (--viewport-md) {
    padding: var(--size-48);
    aspect-ratio: unset;
  }

  & > .headingLayout {
    margin-top: auto;

    @media (--viewport-md) {
      margin-top: var(--size-144);
    }
  }
}
