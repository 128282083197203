.componentBase {
  --text-color: var(--text-color-white);

  display: flex;
  flex-direction: column;
  color: var(--text-color);

  & > .headingLayout {
    margin-bottom: var(--size-16);
  }
}

.componentSecondary {
  --text-color: var(--text-color-default);
}
