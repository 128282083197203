.component {
  border-radius: var(--border-radius-32);
  background-color: var(--bg-color-blue-dark);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > * {
    flex: 1;
  }
}

.imageContainer {
  position: relative;
  z-index: 0;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.imageLayout {
  border-radius: var(--border-radius-32);
}

.content {
  padding: var(--size-24);
  display: grid;
  grid-template-rows: 1fr auto;
  gap: var(--size-32);
  justify-items: start;

  @media (--viewport-md) {
    padding: var(--size-48);
  }
}
