.componentBase {
  --text-color: var(--text-color-default);
  --dot-color: var(--bg-color-orange);

  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-16);

  &.isActive::after {
    content: '';
    top: calc(-1 * var(--dot-size));
    right: calc(-1 * var(--dot-size));
    position: relative;
    display: inline-block;
    width: var(--dot-size);
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--dot-color);
  }
}

.componentSecondary {
  --text-color: var(--text-color-white);
  --dot-color: var(--bg-color-white);

  color: var(--text-color);
}
