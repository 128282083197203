.component {
  padding-top: var(--size-48);

  @media (--viewport-md) {
    padding-block: var(--size-72);
    border-bottom: var(--border-bold) var(--border-color-blue-lighter);
  }
}

.layout {
  @media (--viewport-md) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "content slider";
    gap: var(--size-16);
  }

  @media (--viewport-xl) {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: min-content(0, 700px);
  }

  & > .contentLayout {
    @media (--viewport-md) {
      grid-area: content;
      align-self: end;
    }
  }

  & > .sliderLayout {
    @media (--viewport-md) {
      grid-area: slider;
    }
  }
}

.componentContent {
  display: none;

  @media (--viewport-md) {
    display: flex;
    flex-direction: column;
    gap: var(--size-60);

    /* do NOT use textwrap balance here.
    When using &shy; inside a <strong> block in Sanity, it is rendered like <strong>Schoon</strong>&shy;<strong>maker</strong>. When there is enough space for the entire word, text-wrap: balance will still break that on &shy; when that balances the text better, but does *not* show a hyphen. When there is not enough space for the entire word, it will be broken *with* a hyphen. If we can change the PortableText rendering so that it renders <strong>Schoon&shy;maker</strong> instead, it will work *with* hyphen [Jira ASI-415] */
    text-wrap: pretty;
  }
}
