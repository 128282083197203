.component {
  --squeegee-text-width-sm: 18.75rem;
  --squeegee-text-width-lg: 62.5rem;
  --squeegee-text-offset: -15vmin;
  --squeegee-stroke-sm: 111;
  --squeegee-stroke-lg: 151;
  --squeegee-duration: 4s;
  --squeegee-timing-fallback: var(--in-out-quad);
  --squeegee-timing: linear(
    0 0%,
    0.192 13.6%,
    0.248 31.2%,
    0.376 41.2%,
    0.472 54%,
    0.57 62%,
    0.67 77%,
    0.792 87.6%,
    1 100%
  );

  overflow: hidden;
  display: grid;
  place-content: center;

  & > * {
    grid-area: 1 / 1;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  mask: url(#squeegeeMaskSm);

  @media (--viewport-lg) {
    mask: url(#squeegeeMaskLg);
  }

  & > .textContainer {
    margin-top: var(--squeegee-text-offset);
    max-width: var(--squeegee-text-width-sm);

    @media (--viewport-lg) {
      max-width: var(--squeegee-text-width-lg);
    }
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  align-items: center;

  & > .buttons {
    margin-top: var(--size-24);
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  @media (--viewport-lg) {
    flex-direction: row;
    gap: var(--size-24);
  }
}

.squeegee {
  &.isSm {
    @media (--viewport-lg) {
      display: none;
    }
  }

  &.isLg {
    display: none;

    @media (--viewport-lg) {
      display: block;
    }
  }

  & > .svg {
    max-width: none;
  }
}

.squeegeeMask,
.squeegeePath {
  pointer-events: none;
  stroke-dashoffset: 1;
  stroke-dasharray: 1;
  animation-name: draw;
  animation-duration: var(--squeegee-duration);
  animation-fill-mode: forwards;
  animation-timing-function: var(--squeegee-timing-fallback);

  @supports (animation-timing-function: linear(0, 1)) {
    animation-timing-function: var(--squeegee-timing);
  }

  stroke-width: var(--squeegee-stroke-sm);

  @media (--viewport-lg) {
    stroke-width: var(--squeegee-stroke-lg);
  }
}

.squeegeeMask {
  stroke: var(--stroke-color-white);
}

.squeegeePath {
  stroke: var(--stroke-color-blue-lighter);
}

@keyframes draw {
  from {
    stroke-dashoffset: 1;
  }

  to {
    stroke-dashoffset: 0;
  }
}
