.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-48);
}

.componentStories {
  --item-min-width: 21rem;

  display: grid;
  gap: var(--size-28);
  grid-template-columns: 1fr;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }

  @media (--viewport-xl) {
    grid-template-columns: 1fr 1fr 1fr;
  }

}

.item {
  display: grid;
}
