.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  & > .loaderLayout {
    margin: 2vmin auto;
  }
}

.componentJobs {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.componentJobsResult {
  display: flex;
  flex-direction: column;
  gap: var(--size-48);
}
