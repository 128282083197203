.componentBase {
  --bg-color: var(--bg-color-orange);
  --text-color: var(--text-color-white);
  --icon-size: var(--icon-circle-size-48);
  --svg-size: var(--icon-size-24);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-circle);
  line-height: var(--line-height-button-default);
  transition: var(--duration-300) linear;
  transition-property: background-color, color;
  width: var(--icon-size);
  aspect-ratio: 1;
  background-color: var(--bg-color);
  color: var(--text-color);

  & > .iconLayout {
    flex-shrink: 0;
    width: var(--svg-size);
  }
}

.componentSecondary,
.componentLinkSecondary {
  --bg-color: var(--bg-color-blue-dark);
}
