.componentTextReel {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: var(--size-16);
  padding: var(--size-24);
  background-color: var(--bg-color-blue-dark);
  color: var(--text-color-white);

  & > .textContent {
    flex: 1 1 auto;
  }
}

.bgOrange {
  background-color: var(--bg-color-orange);
}

.bgRed {
  background-color: var(--bg-color-red);
}

.textContent {
  padding-block: var(--size-32);
}

.textContentOnlyText {
  display: flex;
  align-items: center;
}

.linkContainer {
  display: flex;
  align-items: center;
  gap: var(--size-24);

  & > .textReelLink {
    height: 100%;
  }
}

.textReelLink {
  display: flex;
  align-items: center;
  color: var(--color-white);
}

.componentBenefitsList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-8);
}

.componentBenefit {
  display: flex;
  align-items: center;
  gap: var(--size-8);
  border-radius: var(--border-radius-24);
  padding: var(--size-8) var(--size-24) var(--size-8) var(--size-12);
  background-color: var(--color-white--80);
  color: var(--text-color-default);
  font-size: var(--font-size-16);

  & > .benefitIconLayout {
    width: var(--icon-size-24);
  }
}

.componentVideoReel {
  position: relative;
  z-index: 0;

  & > .reelVideoLayout {
    height: 100%;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    height: 4.5rem;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.25),
      transparent
    );
    pointer-events: none; /* prevent ::after from blocking interaction with mute-button */
  }
}
