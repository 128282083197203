.component {
  --input-currency-offset: 1.42ch;
  --result-font-size: var(--font-size-48);
  --result-font-weight: var(--font-weight-medium);
  --vertical-spacing: 2vmin;

  display: flex;
  flex-direction: column;
  /* stylelint-disable-next-line kaliber/layout-related-properties */
  min-height: 100%;

  & > .content {
    flex: 1;
  }
}

.content {
  padding-inline: var(--size-32);
  display: flex;
  flex-direction: column;
  gap: var(--size-8);

  @media (--viewport-md) {
    padding-inline: var(--size-48);
  }

  & > .headingLayout {
    margin-bottom: var(--vertical-spacing);
  }

  & > .buttons {
    margin-bottom: var(--vertical-spacing);
  }

  & > .selects {
    margin-block: var(--vertical-spacing);
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);
}

.box {
  border-radius: var(--border-radius-8);
  padding: var(--size-16) var(--size-24);

  &.primary {
    color: var(--text-color-white);
    background-color: var(--bg-color-orange);
  }

  &.secondary {
    color: var(--text-color-default);
    background-color: var(--bg-color-blue-light);
  }
}

.label {
  line-height: var(--line-height-button-default);
  letter-spacing: var(--font-letterspacing-sm);
  font-size: var(--font-size-16);
}

.numberInputContainer {
  position: relative;
  display: grid;

  & > * {
    grid-area: 1 / 1;
    width: 100%;
  }
}

.numberInputValue {
  pointer-events: none;
  font-size: var(--result-font-size);
  font-weight: var(--result-font-weight);
  border-bottom: var(--border-bold) var(--border-color-white--30);
  transition: var(--duration-300) opacity linear;
  opacity: 0.5;

  &.isActive {
    opacity: 1;
  }
}

.numberInput {
  padding-left: var(--input-currency-offset);
  font-size: var(--result-font-size);
  font-weight: var(--result-font-weight);
  border-bottom: var(--border-bold) transparent;

  &:focus-visible {
    outline: none;
  }
}

.result {
  font-size: var(--result-font-size);
  font-weight: var(--result-font-weight);
}

.disclaimer {
  font-size: var(--font-size-14);
}

.selects {
  display: flex;
  gap: var(--size-16);

  & > .selectLayout {
    flex: 1;
  }
}

.action {
  display: flex;
  padding-inline: var(--size-32);
  padding-block: var(--size-16);
  border-top: var(--border-bold) var(--border-color-blue-light);

  @media (--viewport-md) {
    padding-inline: var(--size-48);
  }

  & > .buttonLayout {
    flex: 1;
  }
}
