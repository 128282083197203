.component {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--size-8);

  @media (--viewport-md) {
    gap: var(--size-32);
  }
}

.bullets {
  /* prev/next buttons are circular buttons with set size. create var so we can use same size for bullets */
  --circularButtonSmSize: var(--size-36);
  --circularButtonSize: var(--size-48);
  --bullet-size: var(--circularButtonSmSize);
  --bullet-gap: var(--size-8);

  @media (--viewport-md) {
    --bullet-size: var(--circularButtonSize);
    --bullet-gap: var(--size-18);
  }

  display: flex;
  align-items: center;
  gap: var(--bullet-gap);
}

.hellip {
  display: flex;
  align-items: center;
  justify-content: center;
}

.componentBullet {
  font-size: var(--font-size-14-18);
  border-radius: var(--border-radius-8);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color var(--duration-150) linear;

  &.active,
  &:focus,
  &:hover {
    background-color: var(--bg-color-blue-dark);
    color: var(--text-color-white);
    font-weight: var(--font-weight-bold);
  }
}

.item {
  & > .bulletLayout {
    width: var(--bullet-size);
    height: var(--bullet-size);
  }
}
