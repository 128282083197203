.componentBase {
  display: grid;
  place-items: center;

  & > .content {
    max-width: var(--size-content-45);
  }
}

.content {
  display: flex;
  flex-direction: column;
  place-items: center;
  text-align: center;
  gap: var(--size-32);
}

.componentText {
  text-wrap: pretty;
}

.componentLinkList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-24);

  & > .linkContainer {
    flex: 1;
  }
}

.linkContainer {
  & > .buttonLinkLayout {
    width: 100%;
  }
}
