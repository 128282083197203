.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

.header {
  display: contents;

  @media (--viewport-md) {
    display: flex;
    justify-content: space-between;
    gap: var(--size-24);
  }

  & > .buttonLayout {
    order: 1;
  }
}

.jobs {
  display: grid;
  gap: var(--size-18);

  @media (--viewport-lg) {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
}

.job {
  & > .jobCardLayout {
    height: 100%;
  }
}
