.component {
  position: relative;
  z-index: 0;

  & > .textToSpeechButtonLayout {
    margin-bottom: var(--size-32);

    @media (--viewport-xxl) {
      position: absolute;
      left: 0;
    }
  }
}
