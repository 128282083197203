.componentBase {
  --border-color: transparent;

  &:hover {
    --border-color: var(--border-color-red);
  }
}

.isActive {
  --border-color: var(--border-color-red);
}

.componentMobile {
  display: block;

  /* settings should match HeaderMobile > .submenuToggle */
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-bold);
}

.componentSubmenuMobile {
  display: block;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-regular);
  padding-block: var(--size-8);
}

.componentDesktop {
  display: block;

  /* settings should match HeaderDesktop > .submenuToggle */
  font-size: var(--size-16);
  padding: var(--size-8) var(--size-16);
}

.linkText {
  /* settings should match .toggleLabel in HeaderDesktop and HeaderMobile */
  border-bottom: var(--border-bold) var(--border-color);
  transition: border-color linear var(--duration-100);
}
