.content {
  padding-bottom: var(--size-18);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-20);

  & > .mediaBlock {
    width: 100%;
  }
}

.block {
  padding-inline: var(--size-24);
  display: grid;
  gap: var(--size-4);
  grid-template-columns: auto 1fr;

  @media (--viewport-lg) {
    padding-inline: var(--size-36);
  }

  & > .icon {
    width: var(--icon-size-36);
  }
}

.mediaBlock {
  padding-inline: var(--size-18);

  & > .mediaContainer {
    width: 100%;
  }
}

.mediaContainer {
  overflow: hidden;
  border-radius: var(--border-radius-24);
}
