.component {
  --card-padding: var(--size-14);
  --card-radius: var(--size-24);

  padding: var(--card-padding);
  border-radius: var(--card-radius);
  border: var(--border-bold) var(--border-color-blue-dark);
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: var(--size-18);

  & > .buttonLayout {
    align-self: end;
    justify-self: start;
  }

  & > .imageContainer {
    grid-row: 1;
  }
}

.imageContainer {
  border-radius: calc(var(--card-radius) - var(--card-padding));
  overflow: hidden;

  & > .image {
    width: 100%;
    height: 100%;
  }
}
