.componentBase {
  position: relative;
  z-index: 0;
  display: grid;
  aspect-ratio: 16 / 9;
  border-radius: var(--border-radius-24);
  border: var(--border-bold) var(--border-color-blue-dark);
  overflow: hidden;

  & > * {
    grid-area: 1 / 1;
  }

  & > .imageLayout {
    z-index: 1;
    height: 100%;
  }

  & > .buttonLayout {
    margin-bottom: var(--size-20);
    margin-left: var(--size-20);
    align-self: end;
    justify-self: start;
    z-index: 2;
  }
}

.playerContainer {
  display: grid;

  & > .player {
    grid-area: 1 / 1;
  }
}
