/*
  Use variables from formAndButtons.css for consistent styling across our forms/buttons and the external application's forms/buttons.
*/
.componentBase,
.componentLinkBase {
  --bg-color: var(--bg-color-button-primary);
  --bg-color-hover: var(--bg-color-button-primary-hover);
  --text-color: var(--text-color-button-primary);
  --font-size: var(--font-size-button-default);
  --padding: var(--padding-button-default);

  padding: var(--padding);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap-button-default);
  border-radius: var(--border-radius-button);
  line-height: var(--line-height-button-default);
  font-weight: var(--font-weight-button);
  letter-spacing: var(--letter-spacing-button);
  transition: background-color var(--duration-300) linear,
    color var(--duration-100) linear,
    border var(--duration-100) linear;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-size: var(--font-size);
  white-space: nowrap;

  &:hover,
  &:focus-visible {
    background-color: var(--bg-color-hover);
  }

  &.isLeft {
    flex-direction: row-reverse;
  }

  &.hasSpaceBetween {
    justify-content: space-between;
  }

  & > .iconLayout {
    flex-shrink: 0;
    width: var(--icon-size-24);
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }

  &:active {
    scale: 0.96;
  }
}

.componentPrimaryLg,
.componentLinkPrimaryLg {
  --font-size: var(--font-size-20);
  --padding: var(--size-24) var(--size-32);

  letter-spacing: 0.0125em;
}

.componentSecondary,
.componentLinkSecondary {
  --bg-color: var(--bg-color-button-secondary);
  --bg-color-hover: var(--bg-color-button-secondary-hover);
}

.componentSecondaryLg {
  --bg-color: var(--bg-color-button-secondary);
  --bg-color-hover: var(--bg-color-button-secondary-hover);
  --text-color: var(--text-color-button-secondary);
  --font-size: var(--font-size-20);
  --padding: var(--size-24) var(--size-32);

  letter-spacing: 0.0125em;
}

.componentLinkTertiaryLg {
  --font-size: var(--font-size-20);
  --padding: var(--size-24) var(--size-32);
  --bg-color: var(--bg-color-button-tertiary);
  --bg-color-hover: var(--bg-color-button-tertiary-hover);
  --text-color: var(--text-color-default);

  letter-spacing: 0.0125em;
}

.componentAccent,
.componentLinkAccent {
  --bg-color: var(--bg-color-red);
  --bg-color-hover: var(--bg-color-red-hover);
}

.componentNeutral,
.componentLinkNeutral {
  --bg-color: var(--bg-color-blue-light);
  --bg-color-hover: var(--bg-color-blue-light-hover);
  --text-color: var(--text-color-default);
}
