.component {
  padding-top: var(--size-36);
  display: grid;
  grid-template-columns:
    var(--container-padding-fluid-lg)
    minmax(min-content, 1fr)
    var(--container-padding-fluid-lg);

  @media (--viewport-lg) {
    padding-top: var(--size-72);
    text-align: center;
  }

  & > .headingLayout {
    grid-column: 2;
  }
}
