.component {
  padding-block: var(--size-24);
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  border-bottom: var(--border-bold) var(--border-color-blue-lighter);
  display: grid;
  grid-template-areas:
    "images"
    "content"
    "metadata";

  @media (--viewport-md) {
    --hero-margin: minmax(
      var(--container-padding-inline),
      calc(50% - var(--container-max-width-xl) / 2)
    );

    grid-template-columns:
      var(--hero-margin)
      minmax(min-content, 2fr)
      minmax(min-content, 1fr)
      var(--hero-margin);
    grid-template-rows: 1fr auto;
    grid-template-areas:
      ". content  images ."
      ". metadata images  .";
  }

  @media (--viewport-lg) {
    padding-block: var(--size-72);
  }

  &::after {
    content: "";
    display: block;
    background-color: var(--bg-color-blue-lighter);
    grid-column: 1 / -1;
    grid-row: 1 / span 2;
    z-index: -1;

    @media (--viewport-md) {
      display: none;
    }
  }

  & > .contentLayout {
    margin-top: var(--size-36);
    grid-area: content;

    @media (--viewport-md) {
      margin-top: unset;
      align-self: end;
    }
  }

  & > .metadata {
    grid-area: metadata;
  }

  & > .imageGridLayout {
    margin-top: var(--size-24);
    grid-area: images;

    @media (--viewport-md) {
      /* margin-left is fixed; margin-right uses % because it depends on size of images */
      margin: 0 -15% 0 var(--size-36);
    }
  }
}

.componentImageGrid {
  padding-inline: var(--container-padding-inline);
  display: flex;
  gap: var(--size-16);

  & > .imageContainer {
    flex: 1;
  }

  @media (--viewport-md) {
    padding-inline: unset;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  & > :nth-child(1) {
    @media (--viewport-md) {
      grid-column: 1;
      grid-row: 2 / span 2;
      align-self: center;
    }
  }

  & > :nth-child(2) {
    @media (--viewport-md) {
      grid-column: 2;
      grid-row: 1 / span 2;
      align-self: end;
    }
  }

  & > :nth-child(3) {
    @media (--viewport-md) {
      grid-column: 2;
      grid-row: 3 / span 2;
      align-self: start;
    }
  }
}

.componentContent {
  padding-inline: var(--container-padding-inline);
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  gap: var(--size-60);
  hyphens: auto;

  @media (--viewport-md) {
    padding-inline: unset;
  }
}

.heading {
  text-wrap: pretty;
}

.button {
  & > .buttonLayout {
    width: min(100%, 22.5rem);
  }
}

.imageContainer {
  border-radius: var(--border-radius-24);
  border: var(--border-bold) var(--border-color-blue-dark);
  overflow: hidden;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.componentActions {
  padding-block: var(--size-24);
  display: flex;
  gap: var(--size-16);
}

.metadata {
  padding-top: var(--size-12);
  padding-inline: var(--container-padding-inline);

  @media (--viewport-md) {
    padding-inline: unset;
  }
}
