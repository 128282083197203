.component {
  --padding-block: var(--hero-block-padding-default);
  --aspect-ratio: 360 / 220;

  @media (--viewport-md) {
    --padding-block: var(--hero-block-padding-md);
    --aspect-ratio: 1;
  }

  @media (--viewport-lg) {
    --padding-block: var(--hero-block-padding-lg);
  }

  padding-block: var(--padding-block);
  background-color: var(--bg-color-blue-lighter);
}

.layout {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--size-16);

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .content {
    flex: 55 55 55%;
  }

  & > .imageContainer {
    flex: 45 45 45%;
  }
}

.content {
  aspect-ratio: var(--aspect-ratio);
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  border: var(--border-bold) var(--border-color-blue-dark);
  border-radius: var(--border-radius-32);
  background-color: var(--bg-color-white);
  padding: var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-32);
    aspect-ratio: auto;
  }

  & > .headingLayout {
    margin-top: auto;
  }
}

.imageContainer {
  overflow: hidden;
  aspect-ratio: var(--aspect-ratio);
  border: var(--border-bold) var(--border-color-blue-dark);
  border-radius: var(--border-radius-32);
  background-color: var(--bg-color-white);

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}
