._rootOverlay {
  & > .panel {
    width: min(100%, 40rem);
    height: 90vh;
    position: fixed;
    bottom: 0;

    @media (--viewport-md) {
      right: 0;
      height: 100lvh;
    }
  }

  & > .dimmedOverlay {
    position: fixed;
    inset: 0;
  }
}

.dimmedOverlay {
  background-color: var(--bg-color-overlay);
  opacity: 1;
  transition: opacity var(--duration-500) linear;

  @starting-style {
    opacity: 0;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  border-radius: var(--border-radius-32) var(--border-radius-32) 0 0;
  background-color: var(--bg-color-white);

  @media (--viewport-md) {
    gap: var(--size-48);
    border-radius: unset;
  }

  & > .closeButtonLayout {
    margin-top: var(--size-24);
    margin-right: var(--size-24);
    align-self: flex-end;
  }

  & > .scroller {
    height: 100%;
  }
}

.scroller {
  overflow: hidden auto;
  overscroll-behavior: contain;
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);

  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
    border-radius: var(--border-radius-8);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: var(--border-radius-8);
  }
}
