.introLayout {
  padding-bottom: 0;
}

.intro {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  & > .introButtonLayout {
    align-self: flex-start;
  }
}

.introFormLink {
  text-decoration: underline;

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }
}
