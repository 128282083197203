.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  & > .cards {
    --padding-above-scrollbar: var(--size-24);

    margin-inline: calc(-1 * var(--container-padding-inline));
    margin-bottom: calc(-1 * var(--padding-above-scrollbar));

    @media (--viewport-md) {
      --padding-above-scrollbuttonLayoutbar: 0;
    }
  }
}

.cards {
  padding-inline: var(--container-padding-inline);
  padding-bottom: var(--padding-above-scrollbar);
  display: flex;
  gap: var(--size-24);
  overflow: auto hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  & > .card {
    flex: 1;
    min-width: 85%;

    @media (--viewport-md) {
      min-width: unset;
    }
  }
}

.card {
  scroll-snap-align: center;

  @media (--viewport-md-max) {
    opacity: 0.5;
    transition: var(--duration-300) opacity linear;
  }

  &.isSnapped {
    @media (--viewport-md-max) {
      opacity: 1;
    }
  }
}

.componentCard {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: var(--size-18);
  position: relative;
  z-index: 0;

  & > * {
    grid-column: 1;
  }

  & > .buttonLayout {
    z-index: 1;
    align-self: end;
    justify-self: start;
    margin-left: var(--size-18);
    margin-bottom: var(--size-18);
  }

  & > .buttonLayout,
  & > .imageContainer {
    grid-row: 1;
  }

  & > .headingLayout {
    grid-row: 2;
  }
}

.imageContainer {
  border-radius: var(--border-radius-24);
  border: var(--border-bold) var(--border-color-blue-dark);
  overflow: hidden;

  & > .image {
    width: 100%;
    height: 100%;
  }
}

/* stylelint-disable-next-line kaliber/parent-child-policy */
.videoLayout {
  /* since we are passing the video through a modal as a {child}, can't use a normal layoutclassname */
  /* stylelint-disable-next-line kaliber/layout-related-properties */
  height: 100%;
}
