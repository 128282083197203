.componentBase {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-4);
  position: relative;

  & > .input {
    width: 100%;
  }
}

.input {
  --border-color: var(--border-color-input-primary);
  --border-color-focus: var(--border-color-input-primary-focus);
  --bg-color: var(--bg-color-input-primary);
  --padding: var(--padding-input-default);
  --font-size: var(--font-size-input-default);

  padding: var(--padding);
  border: var(--border-form-field-default) var(--border-color);
  border-radius: var(--border-radius-form-field-default);
  background-color: var(--bg-color);
  font-size: var(--font-size);

  &:focus-visible {
    outline-offset: 0.1875rem; /* default offset does not look good with small gap between inputs */
  }
}

.componentSecondary {
  --border-color: var(--border-color-input-secondary);
  --border-color-focus: var(--border-color-input-secondary-focus);
  --bg-color: var(--bg-color-input-secondary);
}
