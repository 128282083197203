.component {
  --reels-slider-progress-interval-ms: 100ms; /* keep same as progressIntervalMs in ReelsSlider */

  position: relative;
  z-index: 0;
  overflow: hidden;
  display: grid;
  grid-template-areas: "stack";
  border-radius: var(--border-radius-32);
  border: var(--border-bold) var(--border-color-blue-dark);
  background-color: var(
    --bg-color-blue-dark
  ); /* initial color when slider isn't initialized yet */

  & > .sliderLayout {
    grid-area: stack;
    height: 100%;
  }

  & > .paginatorLayout {
    grid-area: stack;
    position: relative;
    z-index: 1;
    height: fit-content;
  }

  & > .prevNextLayout {
    grid-area: stack;
    z-index: 2;
  }
}

.componentSlider {
  aspect-ratio: var(--aspect-ratio-reels);
  transition: opacity var(--duration-300);
  cursor: grab;

  /* keen slider sets min-height: 100% on slides, so no need for .slideLayout */

  &:active {
    cursor: grabbing;
  }

  & > .slideLayout {
    height: 100%;
  }
}

.sliderIsNotReady {
  /* initially hide slides to prevent seeing unstyled slides layout while slider isn't initialized yet */
  opacity: 0;
}

.componentSlide {
  & > .reelLayout {
    height: 100%;
  }
}

/* paginator */

.componentSlidePaginator {
  display: grid;
  grid-auto-flow: column;
  gap: var(--size-8);
  padding-inline: var(--size-24);

  & > .paginatorButtonLayout {
    height: var(--size-40);
  }
}

.componentPaginatorButton {
  display: flex;
  align-items: center;

  & > .progressIndicatorLayout {
    width: 100%;
    height: var(--size-8);
    height: var(--size-4);
  }
}

.componentProgressIndicator {
  --bar-scale: 0;
  --duration: 0;

  overflow: hidden;
  border-radius: var(--border-radius-pill);
  background-color: var(--bg-color-white--50);

  & > .progressBar {
    width: 100%;
    height: 100%;
  }
}

.progressIndicatorIsFinished {
  --bar-scale: 1;
}

.progressIndicatorIsActive {
  --bar-scale: var(--progress);

  /* only set value > 0 for duration on bar for active slide to prevent unwanted transition on inactive progressbars */
  --duration: var(--reels-slider-progress-interval-ms);
}

.progressBar {
  background-color: var(--bg-color-white);
  scale: var(--bar-scale) 1;
  transform-origin: center left;
  transition: scale var(--duration) linear;
  will-change: transform;
}

.componentPrevNext {
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  align-items: center;

  & > .prevButton,
  & > .nextButton {
    pointer-events: auto;

    /* you want to be able to click at left and right sides, but we have to leave room in the center (so as not to hinder swiping, don't block video's mute-button) and top and bottom (to be able to click on paginator and button) */
    width: 30%;
    height: 70%;
  }
}

.prevButton,
.nextButton {
  border-radius: 50%;
  translate: -50% 0;
  outline: none;
}

.nextButton {
  translate: 50% 0;
}
