.component {
  position: relative;
  z-index: 0;
  display: grid;
  aspect-ratio: var(--aspect-ratio-reels);

  & > * {
    grid-area: 1 / 1;
  }

  & > .muteButtonLayout {
    margin-top: var(--size-48);
    margin-right: var(--size-24);
    justify-self: end;
  }
}

.playerContainer {
  position: relative;
  z-index: -1;
  display: grid;
  pointer-events: none;

  & > .player {
    grid-area: 1 / 1;
  }
}

.player {
  /*
  vimeo player is included in an iframe with landscape orientation we can't access, so scale it up to fit the reel
  */
  --aspect-ratio-vimeo: calc(5 / 7);

  /* --aspect-ratio-reels may be defined as x / y (instead of as a number like 0.67); for the division of the two aspect ratios to work, both must be converted to a number using calc */
  --aspect-ratio-reels-with-calc: calc(var(--aspect-ratio-reels));
  --scale: calc(
    1.01 * var(--aspect-ratio-vimeo) / var(--aspect-ratio-reels-with-calc)
  ); /* multiply with 1.01 to mitigate rounding errors that might result in 1px too small player */

  scale: var(--scale);
}

.debug {
  display: flex;
  gap: 8px;
  justify-self: center;
  align-self: end;
}

.debugButton {
  width: fit-content;
  height: fit-content;
  background: yellow;
  padding: 0 0.5rem;
}
