.component {
  background-color: var(--bg-color-blue-light);
  border-radius: var(--border-radius-24);
  display: grid;
  grid-template-columns:
    var(--container-padding-fluid-lg)
    minmax(min-content, 1fr)
    var(--container-padding-fluid-lg);

  & > .layout {
    grid-column: 2;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding-block: var(--size-32);

  @media (--viewport-md) {
    padding-block: var(--size-48);
  }

  @media (--viewport-lg) {
    padding-block: var(--size-72);
  }
}

.componentColumns {
  display: flex;
  gap: var(--size-12);
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > * {
    flex: 1;
  }
}

.componentColumn {
  display: flex;
  gap: var(--size-12);
  flex-direction: column;
}

.componentItem {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color-white);
  border: var(--border-bold) var(--border-color-blue-dark);
  border-radius: var(--border-radius-24);
}

.componentButtonWithIcon {
  padding: var(--size-16) var(--size-24);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-30-36);
  line-height: var(--line-height-button-default);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-letterspacing-sm);

  @media (--viewport-md) {
    padding: var(--size-18) var(--size-24);
  }

  @media (--viewport-lg) {
    padding: var(--size-18) var(--size-36);
  }

  & > .chevron {
    flex-shrink: 0;
  }
}

.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: rotate var(--duration-150) ease-out;

  &.isOpen {
    rotate: 180deg;
  }

  & > .iconLayout {
    width: var(--icon-size-36);

    @media (--viewport-md) {
      width: var(--icon-size-48);
    }
  }
}

.componentItemDetails {
  overflow: hidden;
  transition: height var(--duration-300) var(--in-out-quad);
  height: 0;

  &.isOpen {
    height: auto;
  }
}
