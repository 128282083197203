.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  & > .list {
    --padding-above-scrollbar: var(--size-24);

    margin-inline: calc(-1 * var(--container-padding-inline));
    margin-bottom: calc(-1 * var(--padding-above-scrollbar));

    @media (--viewport-md) {
      --padding-above-scrollbuttonLayoutbar: 0;
    }
  }
}

.list {
  padding-inline: var(--container-padding-inline);
  padding-bottom: var(--padding-above-scrollbar);
  display: flex;
  gap: var(--size-24);
  overflow: auto hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  scrollbar-width: none;
  touch-action: pan-x;

  ::-webkit-scrollbar {
    display: none;
  }

  & > .item {
    flex: 1;
    min-width: 85%;

    @media (--viewport-md) {
      min-width: unset;
    }
  }
}

.item {
  display: flex;
  scroll-snap-align: center;

  @media (--viewport-md-max) {
    opacity: 0.5;
    transition: var(--duration-300) opacity linear;
  }

  &.isSnapped {
    @media (--viewport-md-max) {
      opacity: 1;
    }
  }
}
