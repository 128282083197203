.componentAndExpandButton {
  display: flex;
  flex-direction: column;

  @media (--viewport-lg) {
    flex-direction: row;
    justify-content: space-between;
    gap: var(--size-20);
  }

  & > .filtersLayout {
    flex: 1;
  }

  & > .buttonLayout {
    margin-top: 1.875rem; /* label height */
    margin-inline: auto;
    align-self: flex-start;
    min-height: 3.438rem; /* same height as filters */
    min-width: 10.25rem; /* prevents filters from moving */
  }
}

.componentJobFilters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);

  & > * {
    flex: 1;
    min-width: max-content;
  }
}

.componentOther {
  display: grid;
  grid-template-columns:
    var(--container-padding-fluid-lg)
    minmax(min-content, 1fr)
    var(--container-padding-fluid-lg);
  transition: height var(--duration-300) var(--in-out-quad);
  height: 0;
  overflow: hidden;

  &.isOpen {
    height: auto;
    animation: showOverflow 0s var(--duration-300) forwards;
  }

  & > .layout {
    grid-column: 2;
  }
}

@keyframes showOverflow {
  to { overflow: visible; }
}

.layout {
  padding-block: var(--size-48);
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--size-48);

  & > * {
    width: min(100%, 23.438rem);
  }
}
