.component {
  pointer-events: none;
  position: relative;
  z-index: 0;

  & > .backdrop {
    position: fixed;
    width: 100vw;
    height: 100%;
    inset: var(--header-height-desktop) 0 0;
    z-index: -1;
  }
}

.backdrop {
  opacity: 0;
  transition: opacity var(--duration-300);
  background-color: var(--bg-color-overlay);

  &.isVisible {
    opacity: 1;
  }
}
