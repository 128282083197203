.component {
  --padding-block: var(--size-32);

  border-radius: var(--border-radius-32);
  border: var(--border-bold);
  background-color: var(--bg-color-white);
  display: grid;
  grid-template-columns:
    var(--container-padding-fluid-lg)
    minmax(min-content, 1fr)
    auto
    var(--container-padding-fluid-lg);
  grid-template-areas:
    ". speech  speech  ."
    ". content content .";

  @media (--viewport-lg) {
    --padding-block: var(--size-72);

    grid-template-areas: ". content speech .";
  }

  & > .layout {
    grid-area: content;
  }

  & > .textToSpeechButtonContainer {
    grid-area: speech;
  }
}

.layout {
  padding-block: var(--padding-block);

  & > .headingLayout {
    margin-bottom: var(--size-24);
  }

  & > .content {
    margin-bottom: var(--size-36);
  }
}

.textToSpeechButtonContainer {
  padding-top: var(--padding-block);
}
