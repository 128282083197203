.component {
  padding-block: var(--size-64);
  border-top: var(--border-bold) var(--border-color-blue-dark);
}

.layout {
  display: grid;
  justify-items: center;
  text-align: center;
  gap: var(--size-36);
  grid-template-areas:
    "logo"
    "social"
    "menu";

  @media (--viewport-lg) {
    grid-template-columns: 1fr auto auto;
    grid-template-areas: "logo menu social";
    justify-items: unset;
    align-items: center;
    text-align: left;
  }

  & > .logosLayout {
    grid-area: logo;
  }

  & > .socialLayout {
    grid-area: social;
  }

  & > .menuLayout {
    grid-area: menu;
  }
}

.componentMenu {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  font-size: var(--font-size-small-text);

  @media (--viewport-lg) {
    gap: var(--size-36);
    flex-direction: row;
  }
}

.componentSocial {
  display: flex;
  gap: var(--size-24);

  & > .link {
    flex-shrink: 0;
  }
}

.componentLogo {
  & > .logoLayout {
    width: 8rem;

    @media (--viewport-lg) {
      width: 11.5rem;
    }
  }
}
