.component {
  padding: var(--size-8) var(--size-12);
  display: inline-flex;
  align-items: center;
  gap: var(--size-8);
  border-radius: var(--border-radius-8);
  line-height: var(--line-height-button-default);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-letterspacing-sm);
  transition: var(--duration-300) linear;
  transition-property: background-color, color;
  background-color: var(--bg-color-blue-light);
  color: var(--text-color-default);
  font-size: var(--font-size-16);

  @media (--viewport-lg) {
    /* because of my poor setup of floating ui structure, menu button's floating panel was placed higher than the regular menu items' panels. Increasing padding-block here was the quickest fix - we may need to check the menu setup anyway */
    padding-block: var(--size-12);
  }

  &:hover,
  &:focus-visible {
    background-color: var(--bg-color-blue-light-hover);
  }

  &:active {
    scale: 0.96;
  }

  & > .iconLayout {
    flex-shrink: 0;
    width: var(--icon-size-24);
  }
}

.label {
  font-weight: var(--font-weight-bold);
}
