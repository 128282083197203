/* stylelint-disable kaliber/selector-policy */
.componentBase {
  strong {
    font-weight: var(--font-weight-bold);
  }

  em {
    font-style: italic;
  }
}

.componentMainContent {
  overflow: auto; /* Disable margin collapse */

  h2 {
    font-size: var(--font-size-32-40);
  }

  h3 {
    font-size: var(--font-size-24);
  }

  :is(h2, h3, p, ul, ol) {
    margin-bottom: 0.5em;

    &:last-child {
      margin-bottom: 0;
    }

    &:empty {
      display: none;
    }
  }

  :is(p, ul, ol) + :is(h2, h3) {
    margin-top: 1.5em;
  }

  :is(ul, ol) {
    list-style-type: disc;
    list-style-position: outside;
    padding: 0 var(--size-24);
  }

  ol {
    list-style-type: decimal;
  }

  :is(h2, h3) {
    font-family: var(--font-family-base);
    font-weight: var(--font-weight-heading-default);
    line-height: var(--line-height-heading-default);
    color: var(--heading-text-color-default);
  }
}

.altList {
  :is(h2, h3) {
    font-weight: var(--font-weight-bold);

    @nest :is(p, ul, ol) + & {
      margin-top: 2.5em;
    }
  }

  :is(ul, ol) {
    margin-right: var(--size-24);
    list-style: none;
    padding: 0;
  }

  li {
    padding: var(--size-12) var(--size-18);
    background-color: var(--bg-color-blue-lighter);
    border-radius: var(--border-radius-24);
    line-height: var(--line-height-text-small);

    &:not(:last-child) {
      margin-bottom: var(--size-12);
    }
  }
}

.componentReelsSliderHeroContent,
.componentReelsSliderContent {
  h1 {
    word-break: break-word;
    text-wrap: pretty;
  }

  ol,
  ul {
    display: flex;
    flex-direction: column;
    gap: var(--size-16);
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: var(--size-32);
    line-height: var(--line-height-heading-default);

    &:nth-child(n + 2) {
      padding-top: var(--size-24);
    }
  }

  ul {
    list-style-type: none;

    li {
      position: relative;
      z-index: 0;

      &::before {
        content: "";
        position: absolute;
        left: calc(-1 * var(--size-32));
        width: var(--size-24);
        height: var(--size-24);
        /* you can't use currentColor (or css-vars for that matter) in an image url, since its value won't be interpolated. So use bg-color with mask instead */
        background-color: currentColor;
        mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23193154' d='M18.781 7.219c.282.312.282.781 0 1.062l-8.25 8.25c-.312.313-.781.313-1.062 0l-4.25-4.25a.684.684 0 0 1 0-1.031.684.684 0 0 1 1.031 0l3.719 3.719 7.75-7.75a.736.736 0 0 1 1.031 0h.031Z'/%3E%3C/svg%3E");
        mask-size: contain;
      }
    }
  }
}
