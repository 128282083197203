.message {
  font-size: var(--font-size-16);
  color: var(--text-color-white);
  transition: opacity var(--duration-300) linear,
    translate var(--duration-500) var(--overshoot-out);

  @starting-style {
    opacity: 0;
    translate: 0 calc(-1 * var(--size-8));
  }
}
