.component {
  display: grid;
  grid-template-columns:
    var(--container-padding-fluid-lg)
    minmax(min-content, 1fr)
    var(--container-padding-fluid-lg);
  background-color: var(--bg-color-blue-light);
  border-radius: var(--border-radius-24);

  & > .layout {
    grid-column: 2;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding-block: var(--size-32);

  @media (--viewport-md) {
    padding-block: var(--size-48);
  }

  @media (--viewport-lg) {
    padding-block: var(--size-72);
  }
}

.jobFamilies {
  --item-min-width: 21rem;

  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-12);

  @media (--viewport-sm) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--item-min-width), 1fr)
    );
  }

  @media (--viewport-md) {
    gap: var(--size-18);
  }
}

.jobFamily {
  & > .linkLayout {
    width: 100%;
    height: 100%;
  }
}

.componentLink {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-button-default);
  padding: var(--size-16) var(--size-24);
  background-color: var(--bg-color-white);
  border: var(--border-bold) var(--border-color-blue-dark);
  border-radius: var(--border-radius-24);
  font-size: var(--font-size-24-28);
  line-height: var(--line-height-button-default);
  font-weight: var(--font-weight-medium);
  transition: var(--duration-100) linear;
  transition-property: background-color, color;

  @media (--viewport-md) {
    padding: var(--size-18) var(--size-24);
  }

  @media (--viewport-lg) {
    padding-inline: var(--size-36);
  }

  &:hover,
  &:focus-visible {
    background-color: var(--bg-color-blue-dark);
    color: var(--text-color-white);
  }

  & > .iconLayout {
    flex-shrink: 0;
    width: var(--icon-size-36);

    @media (--viewport-md) {
      width: var(--icon-size-48);
    }
  }
}
