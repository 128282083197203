.componentBase,
.componentLinkBase {
  --bg-color: var(--bg-color-orange);
  --bg-color-hover: var(--bg-color-orange-hover);
  --text-color: var(--text-color-white);
  --icon-size: var(--icon-circle-size-48);
  --svg-size: var(--icon-size-24);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-circle);
  line-height: var(--line-height-button-default);
  transition: var(--duration-300) linear;
  transition-property: background-color, color;
  width: var(--icon-size);
  background-color: var(--bg-color);
  color: var(--text-color);
  aspect-ratio: 1;

  &:hover,
  &:focus-visible {
    background-color: var(--bg-color-hover);
  }

  &.isLeft {
    flex-direction: row-reverse;
  }

  & > .iconLayout {
    flex-shrink: 0;
    width: var(--svg-size);
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }

  &:active {
    scale: 0.96;
  }
}

.componentPrimaryLg,
.componentLinkPrimaryLg {
  --icon-size: var(--icon-circle-size-72);
}

.componentSecondary,
.componentLinkSecondary {
  --bg-color: var(--bg-color-blue-dark);
  --bg-color-hover: var(--bg-color-blue-dark-hover);
}

.componentAccent,
.componentLinkAccent {
  --bg-color: var(--bg-color-red);
  --bg-color-hover: var(--bg-color-red-hover);
}

.componentNeutral,
.componentLinkNeutral {
  --bg-color: var(--bg-color-blue-light);
  --bg-color-hover: var(--bg-color-blue-light-hover);
  --text-color: var(--text-color-default);
}

.componentNeutralSm,
.componentLinkNeutralSm {
  --bg-color: var(--bg-color-blue-light);
  --bg-color-hover: var(--bg-color-blue-light-hover);
  --text-color: var(--text-color-default);
  --icon-size: var(--icon-circle-size-36);
  --svg-size: var(--icon-size-24);
}
